"use strict";

import assert from "@charlesderek/assert";
import { DateTime } from "luxon";
export { display_time }; // List of tokens: https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens

function display_time(time, _ref) {
  var _ref$military_format = _ref.military_format,
      military_format = _ref$military_format === void 0 ? false : _ref$military_format;
  var d;

  if (time.constructor === Date) {
    d = DateTime.fromJSDate(time);
  } else if (time.constructor === Number) {
    d = DateTime.fromMillis(time);
  } else {
    assert(false, {
      time: time
    });
  }

  var format = military_format ? "HH:mm" : "h:mm a";
  var time_string = d.toFormat(format);
  return time_string;
}