"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { store } from "../../store";
migrate_to_centralized_store.schema_version = new Date("2020-04-21");
export { migrate_to_centralized_store };

function migrate_to_centralized_store() {
  Object.entries(localStorage).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        val = _ref2[1];

    if (key.startsWith("timer_") || key.startsWith("clock_") || key.startsWith("countdown_") || key === "thanks-for-your-donation") {
      if (key.endsWith("_presets")) {
        val = JSON.parse(val);
      }

      if (key === "thanks-for-your-donation") {
        val = true;
      }

      store.set_val(key, val);
      window.localStorage.removeItem(key);
    }
  });
  store.migrate__rename_keys({
    "thanks-for-your-donation": "ad_removal"
  });
}