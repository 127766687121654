"use strict";

export { sleep };

function sleep(_ref) {
  var seconds = _ref.seconds,
      milliseconds = _ref.milliseconds;
  milliseconds = milliseconds || seconds * 1000;
  return new Promise(function (resolve) {
    return window.setTimeout(function () {
      resolve();
    }, milliseconds);
  });
}