const browserConfig = require('/home/romu/code/timertab/node_modules/@charlesderek/browser-config/index.js');

browserConfig.initFunctions = {};

browserConfig.initFunctions = browserConfig.initFunctions || {};
browserConfig.initFunctions['hydratePage'] = (() => {const ret = require('/home/romu/code/timertab/node_modules/@goldpage/browser/hydratePage.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;

browserConfig.pageConfig = (() => {const ret = require('/home/romu/code/timertab/views/TimerPage/timer.page.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;

browserConfig['router'] = (() => {const ret = require('/home/romu/code/timertab/node_modules/@goldpage/path-to-regexp/router.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;

browserConfig['renderPageToDom'] = (() => {const ret = require('/home/romu/code/timertab/node_modules/@goldpage/html/renderPageToDom.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;

browserConfig['domRender'] = (() => {const ret = require('/home/romu/code/timertab/tab-utils/_render/browser/domRender.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;

(() => {const ret = require('/home/romu/code/timertab/node_modules/@goldpage/browser/browserInit.js'); return (ret||{}).__esModule===true ? ret.default : ret;})();;
