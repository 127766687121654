"use strict";

import assert from "@charlesderek/assert";
export { getGptSlot, getEzoicSlot, getAdsenseSlotId, isAdsense, isEzoic, isGpt, getGptSlots, getAdsenseSlots };

function getEzoicSlot(slot_name, ad_slots) {
  var ezoic_slots = getEzoicSlots(ad_slots).filter(function (slot) {
    assert(slot_name);
    assert(slot.slot_name);
    return slot.slot_name === slot_name;
  });
  assert(ezoic_slots.length <= 1);
  var slot = ezoic_slots[0];
  if (!slot) return null;
  var slot_id = slot.slot_id;
  assert(slot_id);
  return slot;
}

function getGptSlot(slotName, adSlots) {
  var slots = getGptSlots(adSlots).filter(function (slot) {
    assert(slotName);
    assert(slot.slot_name);
    return slot.slot_name === slotName;
  });
  assert(slots.length <= 1);
  var slot = slots[0];
  if (!slot) return null;
  return slot;
}

function getAdsenseSlotId(slotName, ad_slots) {
  var adsense_slots = getAdsenseSlots(ad_slots).filter(function (slot) {
    assert(slotName);
    assert(slot.slot_name);
    return slot.slot_name === slotName;
  });
  assert(adsense_slots.length <= 1);
  var slot = adsense_slots[0];
  if (!slot) return null;
  var slot_id = slot.slot_id;
  assert(slot_id);
  return slot_id;
}

function getEzoicSlots(AD_SLOTS) {
  return AD_SLOTS.filter(isEzoicSlot);
}

function getAdsenseSlots(AD_SLOTS) {
  return AD_SLOTS.filter(isAdsenseSlot);
}

function getGptSlots(AD_SLOTS) {
  return AD_SLOTS.filter(isGptSlot);
}

function isGpt(adSlots) {
  var len = getGptSlots(adSlots).length;
  assert(len === 0 || len === adSlots.length);
  return len > 0;
}

function isEzoic(adSlots) {
  var len = getEzoicSlots(adSlots).length;
  assert(len === 0 || len === adSlots.length);
  return len > 0;
}

function isAdsense(adSlots) {
  var len = getAdsenseSlots(adSlots).length;
  assert(len === 0 || len === adSlots.length);
  return len > 0;
}

function isEzoicSlot(adSlot) {
  return "is_ezoic" in adSlot;
}

function isAdsenseSlot(adSlot) {
  return "is_adsense" in adSlot;
}

function isGptSlot(adSlot) {
  return "is_gpt" in adSlot;
}